import { useEffect } from 'react';

import { message } from 'utilities/types';

import DrawerMessage from './DrawerMessage';

const DrawerMessagesContainer = ({
  messages,
  onClickMessage,
  onExpireMessage,
  onOpenOnboardingModal,
  onUpdateMessages,
}) => {
  const unreadMessages = messages.filter(message => !message.readAt);
  const numUnread = unreadMessages.length;

  useEffect(() => {
    const onUnMount = async params => await onUpdateMessages(params);

    const messageParams = unreadMessages.map(message => ({ id: message.id, read: true }));

    return () => {
      if (messageParams.length) onUnMount(messageParams);
    };
  }, []);

  if (!messages.length)
    return (
      <p className="py-4 text-center italic">
        You're all caught up! Check back later for new notifications.
      </p>
    );

  return (
    <React.Fragment>
      <p className="textSm border-b border-solid border-charcoal-60 p-6 pt-2">
        You have {numUnread === 1 ? '1 unread message' : `${numUnread} unread messages`}.
      </p>
      <ul className="m-0">
        {messages.map((m, index) => (
          <DrawerMessage
            key={index}
            {...m}
            read={Boolean(m.readAt)}
            onClickMessage={() => onClickMessage(m)}
            onExpireMessage={() => onExpireMessage(m.id)}
            onOpenOnboardingModal={onOpenOnboardingModal}
          />
        ))}
      </ul>
    </React.Fragment>
  );
};

DrawerMessagesContainer.propTypes = {
  messages: PropTypes.arrayOf(message).isRequired,
  onUpdateMessages: PropTypes.func.isRequired,
  onClickMessage: PropTypes.func.isRequired,
  onExpireMessage: PropTypes.func.isRequired,
  onOpenOnboardingModal: PropTypes.func.isRequired,
};

export default DrawerMessagesContainer;
